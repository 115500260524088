import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Perkenalkan, Saya <span className="purple">Hatta Dinata </span>
            dari <span className="purple"> Jakarta, Indonesia.</span>
            <br />Saya seorang mahasiswa jurusan
            <span className="purple"> Informatika </span>
            di salah satu perguruan tinggi di kota Purwokerto.
            <br />
            Selain menjadi mahasiswa, Saya juga aktif menjadi
            <span className="purple"> Freelancer.</span>
            <br />
            <br />
            Beberapa kegiatan lain yang saya sukai !
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Mendengarkan Musik 🎵
            </li>
            <li className="about-activity">
              <ImPointRight /> Bermain Games 🎮
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling 🧳
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
